<template>
    <div class="discover">
        <div class="video_content">
            <el-row :gutter="16">
                <el-col
    :span="4"
    v-for="(item, index) in videoArr"
    :key="index">
    <div class="grid-content bg-purple">
        <div class="img_box" 
            @mouseover="videoPlayback(index, videoArr[index].videoPath)" 
            @mouseout="videoStopped(index, videoArr[index].videoPath)"
            @click="toPlayer(index)">
            <img :src="videoArr[index].coverPath" alt="" ref="img"/>
            <!-- 禁止右键 -->
            <div class="video_box">
                <video
                    ref="video"
                    width="100%"
                    height="100%"
                    @contextmenu.prevent
                    controls
                    controlslist="nodownload nofullscreen noremote footbar"
                    disablePictureInPicture
                    muted="muted"
                    style="display: none; object-fit: fill">
                    <source type="audio/mp4" :src="videoArr[index].videoPath" />
                </video>
            </div>
        </div>
        <!-- 标题 -->
        <div class="tit">
            <span>{{ videoArr[index].videoDesc }}</span>
        </div>
        <!-- 用户及视频上传时间 -->
        <div class="actcont-auto">
            <img :src="videoArr[index].headImage" alt="">
            <span class="uname">{{ videoArr[index].nickName }}</span>
        </div>
    </div>
</el-col>

            </el-row>
        </div>
    </div>
</template>

<script>
import userStore from '../../store/userStore';
export default {
    data() {
        return {
            videoArr: [],
            videoTime: "",
            timer: "",
            pageNumber:1,
        };
    },
    mounted() {
        this.videoList();
    },
    methods: {
        videoList() {
			this.$http({
				url: `/short/listall`,
                params: {
                    pageNumber: this.pageNumber
                },
				method: 'get'
			}).then((data) => {
                 this.videoArr = data;
			});
        },
        videoPlayback(index, video_url) {
            const video = this.$refs.video[index];
        if (video_url) {
            // 显示播放器
            video.style.display = "block";
            this.$refs.img[index].style.display = "none";
            // 定时器播放
            this.timer = setTimeout(() => {
                video.play();
                video.muted = true; // 保持静音播放
            }, 200);
        }
},
        videoStopped(index, video_url) {
            const video = this.$refs.video[index];
        if (video) {
            video.pause();
            video.style.display = "none";
            this.$refs.img[index].style.display = "block";
        }
        },
        toPlayer(index) {
        const video = this.$refs.video[index];
        if (video.requestFullscreen) {
            video.requestFullscreen(); // 进入全屏模式
        } else if (video.webkitRequestFullscreen) { // Safari
            video.webkitRequestFullscreen();
        } else if (video.mozRequestFullScreen) { // Firefox
            video.mozRequestFullScreen();
        } else if (video.msRequestFullscreen) { // IE/Edge
            video.msRequestFullscreen();
        }
        video.muted = false; // 取消静音
        video.volume = 1; // 设置音量为最大值
        video.play(); // 播放视频
    }
    }
};
</script>

<style lang="less" scoped>
.discover {
    width: 100%;
    height: 100%;
    .video_content {
        width: 100%;
        height: 100%;
    }
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.el-col {
    border-radius: 4px;
    margin-bottom: 24px;
}
.bg-purple-dark {
    background: #99a9bf;
}
.bg-purple {
    background: none;
}
.bg-purple-light {
    background: #e5e9f2;
}
.grid-content {
    border-radius: 4px;
    min-height: 36px;
    overflow: hidden;
}
.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
.el-col-4 {
    width: 20%;
}
// 视频信息样式
.img_box {
    width: 100%;
    max-height: 240px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    img, .video_box {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img {
        // 解决图片底部默认空白缝隙问题
        display: block;
    }
}
.tit {
    width: 100%;
    height: 46px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow:ellipsis;
    text-align: left;
    span {
        font-size: 16px;
        line-height: 24px;
        color: rgba(255,255,255,.9);
    }
    span:hover {
        color: #fff;
    }
}
.actcont-auto {
    position: relative;
    width: 100%;
    height: 34px;
    // margin-top: 8px;
    line-height: 34px;
    display: flex;
    img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }
    .uname {
        display: inline-block;
        max-width: 120px;
        height: 100%;
        color: rgba(255,255,255,.9);
        overflow: hidden;
        text-overflow:ellipsis;
        padding-left: 10px;
        font-size: 12px;
    }
    .uname:hover {
        color: rgb(242, 42, 81);
    }
    .mtime {
        max-width: 120px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        color: rgba(255,255,255,.5);
        overflow: hidden;
    }
}
// 播放器样式
video::-webkit-media-controls-timeline { // 进度条
    width: 100%;
    padding: 0;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display{
    display: none !important;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}
//音量按钮
video::-webkit-media-controls-mute-button {
    display: none !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none !important;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
    display: none !important;
}
// 全屏按钮
video::-webkit-media-controls-fullscreen-button {
    display: none !important;
}
//播放按钮
video::-webkit-media-controls-play-button {
    display: none !important;
}
// 播放控件
video::-webkit-media-controls { // 进度条
    opacity: 1;
    // 让鼠标指针一直显示
    cursor: pointer !important;
}
</style>